<template>
  <div>

    <!--Table Card-->
    <b-card no-body>
      <b-card-header class="pb-50">
<!--        <h5>{{$t("menu.orgId")}}</h5>-->
      </b-card-header>
      <b-card-body>
<!--        v-bind:filters="filter"-->
        <organizations-table ref="organizationTable" v-bind:columns="columns"
                             :has-selection="false" @selected="onOrganizationSelected" :lazy="true"/>
      </b-card-body>
    </b-card>

    <!--Modal-->
<!--    <columns-modal ref="columns-modal" column-key="users" v-model="columns"-->
<!--                   v-bind:columns-names="columnsNames" />-->
    <organization-modal ref="organization-modal" />

  </div>
</template>

<script>

import OrganizationsTable from '@/components/tables/OrganizationsTable'
import FilterCard from '@/components/widget/FilterCard';
import OrganizationModal from '@/components/modal/OrganizationModal';
import ColumnsModal from '@/components/modal/columnsModal';
import BreadcrumbButton from '@/components/widget/breadcrumbButton';
import mixinBase from '@/components/mixin/mixinBase';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import User from '@/model/user/User';
import UserSearchRequest from '@/model/user/UserSearchRequest';
import UserFilterFields from '@/model/filter/UserFilterFields';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'OrganizationsView',
  components: {OrganizationsTable, FilterCard, OrganizationModal, ColumnsModal, BreadcrumbButton },
  mixins: [mixinBase,mixinPermissions],
  data() {
    return {
      //columnsNames: Object.keys(User()),
      // filter: UserSearchRequest(),
      filterLoaded: false,
      columns: [],
      // filterFields: null,
    }
  },
  async created() {
    // this.filterFields = UserFilterFields();
  },
  computed: {
    ...mapGetters('data', ['allOrganizations']),
  },
  methods: {
    // openColumnsModal() {
    //   this.$refs['columns-modal'].showModal()
    // },
    onOrganizationSelected(selectedId) {
      console.log("onUserSelected", selectedId)
      this.$refs['organization-modal'].showModal(selectedId)
    },
  }


};
</script>

<style scoped>

.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
  height: 10px;
}
</style>
