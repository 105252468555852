export default function (other) {
  other = other || {}
  return {
    id: other.id || null,
    platformId: other.platformId || null,
    name: other.name || null,
    acronym: other.acronym || null,
    phone: other.phone || null,
    contactName: other.contactName || null,
    crm: other.crm || null,
    website: other.website || null,
    accountPath: other.accountPath || null,
    country: other.country || null,
    lang: other.lang || null,
    email: other.crm || null,
    vat: other.vat || null,
    hebCalendar: other.hebCalendar || null,
    price: other.price || null,
    priceType: other.priceType || null,
    settings: other.settings || null,
    images: other.images || null,
    allowedDomains: other.allowedDomains || null,
    currencies: other.currencies || null,
  }
}
