<template>
  <div>
    <b-row>
      <b-col cols="12">
        <h4 class="text-primary text-center text-uppercase">
          <i class="fa-solid fa-building-circle-exclamation"></i>
          Details Organization
        </h4>
      </b-col>
      <b-row no-gutters style="width: 100%">
        <b-col cols="9">
          <b-row class="m-0 p-0" style="width: 100%">
            <!-- name -->
            <b-col cols="4">
              <label>{{ $t('columns.firstName') }}</label>
              <b-form-input v-model="organization.name" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
            </b-col>
            <!-- platformId -->
            <b-col cols="4">
              <label>platformId</label>
              <b-form-input v-model="organization.platformId" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
            </b-col>
            <!-- contactName -->
            <b-col cols="4">
              <label>Contact Name</label>
              <b-form-input v-model="organization.contactName"
                                    :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"
              />
            </b-col>
            <!-- phone-->
            <b-col cols="4">
              <label>Phone</label>
              <b-input-group>
                <b-form-input v-model="organization.phone" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
                <b-input-group-append>
                  <a
                      class="btn btn-outline-primary" target="_blank"
                      v-b-tooltip :title="$t('operations.call')"
                  >
                    <i class="fa-solid fa-phone-volume text-primary"></i>
                  </a>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <!-- email -->
            <b-col cols="4">
              <label>{{ $t('columns.email') }}</label>
              <b-input-group>
                <b-form-input v-model="organization.email" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
                <b-input-group-append>
                  <a
                      class="btn btn-outline-primary" target="_blank"
                      v-b-tooltip :title="$t('operations.sendMail')"
                  >
                    <i class="fa-solid fa-envelope"></i>
                  </a>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <!-- acronym -->
            <b-col cols="4">
              <label>acronym</label>
              <b-input-group>
                <b-form-input v-model="organization.acronym" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
              </b-input-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <!--id-->
      <b-col cols="4" xl="3">
        <label>id</label>
        <b-form-input v-model="organization.id" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <!--crm-->
      <b-col cols="4" xl="3">
        <label>crm</label>
        <b-form-input v-model="organization.crm" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <!--website-->
      <b-col cols="4" xl="3">
        <label>website</label>
        <b-form-input v-model="organization.website" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <!--accountPath-->
      <b-col cols="4" xl="3">
        <label>accountPath</label>
        <b-form-input v-model="organization.accountPath" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <!--country-->
      <b-col cols="4" xl="3">
        <label>country</label>
        <b-form-input v-model="organization.country" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <!--lang-->
      <b-col cols="4" xl="3">
        <label>lang</label>
        <b-form-input v-model="organization.lang" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <!--vat-->
      <b-col cols="4" xl="3">
        <label>vat</label>
        <b-form-input v-model="organization.vat" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <!--hebCalendar-->
      <b-col cols="4" xl="3">
        <label>heb Calendar</label>
        <b-form-input v-model="organization.hebCalendar" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <!--price-->
      <b-col cols="4" xl="3">
        <label>price</label>
        <b-form-input v-model="organization.price" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
      <!--priceType-->
      <b-col cols="4" xl="3">
        <label>priceType</label>
        <b-form-input v-model="organization.priceType" :readonly="!hasPermission(permissions.CLIENT_UPDATE_CONTACT)"/>
      </b-col>
    </b-row>

    <!-- buttons to save or exit -->
    <div class="text-center mt-2" style="position: absolute;bottom: 10px; width: 100%">
      <b-button block size="md" variant="primary" @click="update">{{$t('operations.save')}}</b-button>


    </div>
  </div>
</template>

<script>
import mixinPermissions from '@/components/mixin/mixinPermissions';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import Organization from '@/model/organization/Organization';
import { mapActions } from 'vuex';

export default {
  name: 'OrganizationEditDetails',
  mixins: [mixinPermissions, mixinNotifications],
  data: () => ({
    organization: Organization(),
  }),
  props: {
    value: {
      required: true,
      default: () => ({})
    },
    newOrganization: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    value: {
      handler() {
        this.organization = Object.assign(Organization(), this.value)
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions('organizations', ['updateOrganization']),

    async update(){
      let $this = this;
      try{
        let req= {id: $this.organization.id, organization: $this.organization}
        await this.updateOrganization(req)
      }catch (e){
        $this.showErrorNotification($this.$t('globalMessages.fetchDataError',
            { system: "error in update organization" }), e.response.data.error)
      }

    },
  }

};
</script>

<style scoped>

</style>
