<template>
  <b-modal no-fade v-model="dialog.show" size="xl" :id="`modal-organization-${uniqueId}`"
           :hide-footer="true" centered @close="onDialogClose"
           no-close-on-backdrop
  >

    <template #modal-title>
     <span v-if="loading !== true && error == null && organization != null">
     {{organization.platformId}} -   {{ organization.name }}  {{ organization.acronym }}
     </span>
    </template>
    <div style="min-height: 600px">
      <div v-if="loading !== true && error == null">
        <organization-edit-details v-bind:new-organization="isNewOrganization" v-model="organization" />
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%" v-else-if="loading !== true && error != null">
        <b-alert show variant="danger" >
          <i class="fa-sharp fa-solid fa-triangle-exclamation"></i>
          {{ error }}
        </b-alert>
      </div>
      <div class="text-center position-absolute d-block w-100" style="top:50%" v-else>
        <b-spinner>{{$t('operations.loading')}}</b-spinner>
      </div>
    </div>


  </b-modal>
</template>

<script>
import mixinBase from '@/components/mixin/mixinBase';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import { mapActions } from 'vuex';
import OrganizationEditDetails from '@/components/partial/organizations/OrganizationEditDetails';

export default {
  name: 'OrganizationModal',
  components: { OrganizationEditDetails },
  mixins: [mixinBase, mixinNotifications],
  data: () => ({
    dialog: {
      show: false,
    },
    uniqueId: 0,
    loading: true,
    error: null,
    id: null,
    isNewOrganization: false,
    organization: {}
  }),
  watch: {
    queryParams:{
      handler(){
        if(this.queryParams.userId == null) {
          this.closeModal()
          return
        }
        if (this.queryParams.userId !== this.id) {
          this.showModal(this.queryParams.userId)
        }
      },
      deep: true
    }
  },
  // created() {
  //   this.uniqueId = this._uid
  //   if(this.queryParams.userId != null) {
  //     this.showModal(this.queryParams.userId)
  //   }
  // },
  methods: {
    ...mapActions('organizations', ['getOrganizationById']),
    showModal(id) {
      this.uniqueId = id
      this.loading = true
      this.dialog.show = true
      this.dialog.tab = 0
      this.isNewOrganization = this.id == null || this.id === 0;
      // if(!this.isNewUser) {
      this.refreshData()
      // }else {
      //   this.user = User()
      //   this.error = null
      //   this.loading = false
      // }
      this.pushState({organizationId: id, tab: 0, })
    },
    closeModal(){
      this.dialog.show = false;
      this.organization = {}
      this.onDialogClose()
    },
    onDialogClose(){
      this.id = null;
    },
    refreshData() {
      let $this = this;
      this.getOrganizationById(this.uniqueId)
          .then(organization =>
          {
            console.log("organizaton in model", organization);
            $this.organization = organization;
            console.log("organizaton in model", $this.organization);

          }, err => {
            console.log(err);
            // $this.error = $this.getErrorDescription(err)
          }).finally(()=>{ $this.loading = false })
    },
  }

};
</script>

<style scoped>

</style>
